<template>
  <v-container
    class="error-page fill-height"
    tag="section"
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col cols="auto">
        <h1 class="text-h6 font-weight-black">
          401
        </h1>

        <div class="text-h2 mb-5 mt-10">
          Session Expired
        </div>

        <common-trs-btn
          depressed
          @click="navigateTo()"
        >
          Navigate to login!
        </common-trs-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'JwtExpired',
    computed: {
      isCaptableAdmin () {
        return this.$store.getters['auth/isCaptableAdmin']
      },
      source () {
        return this.$store.getters['auth/source']
      },
    },
    methods: {
      navigateTo () {
        if (this.isCaptableAdmin || this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.V2) {
          window.open(`${this.CONSTS.MY_DE_PORTAL}`, '_self')
        } else if (this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.DL) {
          window.open(`${this.CONSTS.DL_PORTAL}`, '_self')
        } else {
          window.open(`${this.CONSTS.ISSUER_CAPTABLE}`, '_self')
        }
      },
    },
  }

</script>

<style lang="sass">
  .error-page
    h1
      font-size: 12rem
</style>
